import React from "react";
import { Link } from "react-router-dom";
import { FaLinkedin, FaEnvelope, FaWhatsapp } from "react-icons/fa";
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-logo">XEROVERSE</div>
        <div className="footer-content">
          <div className="footer-section">
            <h3>Pages</h3>
            <ul>
              <li>
                <Link to="/about-us">About Us</Link>
              </li>
              <li>
                <Link to="/service">Services</Link>
              </li>
              <li>
                <Link to="/blog">Blogs</Link>
              </li>
            </ul>
          </div>
          <div className="footer-section">
            <h3>Location</h3>
            <p>
              CNDE Lab, 2nd floor,
              <br />
              Indian Institute Of Technology, Chennai,
              <br />
              Tamil Nadu 600036
            </p>
          </div>
          <div className="footer-section">
            <h3>Contact Details</h3>
            <p>📧 info@xeroverse.in</p>
            <p>📞 +91 7571006656</p>
          </div>
          <div className="footer-section">
            <h3 className="iconheader">Get in touch</h3>
            <div className="social-icons">
              <a href="https://www.linkedin.com/" aria-label="LinkedIn">
                <FaLinkedin />
              </a>
              <a href="mailto:info@xeroverse.in" aria-label="Email">
                <FaEnvelope />
              </a>
              <a href="https://wa.me/917571006656" aria-label="WhatsApp">
                <FaWhatsapp />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
