import React, { useState, useRef, useEffect } from "react";
import "./VideoSection.css";
import ContactUS from "../ContactUs/ContactUs";

const VideoSection = () => {
  const [currentVideo, setCurrentVideo] = useState(0);
  const [contactform, setContactForm] = useState(false);

  const videoRef = useRef(null);
  const overlayRef = useRef(null);

  function handleClick() {
    setContactForm(true);
  }

  function handleClose() {
    setContactForm(false);
  }

  function handleOverlayClick(e) {
    if (e.target === overlayRef.current) {
      handleClose();
    }
  }

  const videos = [
    {
      type: "3D",
      src: "https://xeroverse-media.s3.ap-south-1.amazonaws.com/3d+Walkthrough+web+optimized.mp4",
      alt: "3D Walkthrough",
      title: "3D Walkthrough: Immersive Virtual Tours",

      description:
        "3D walkthrough videos provide immersive virtual tours of properties, buildings, or designs, offering a lifelike, interactive exploration. They offer a realistic sense of scale and layout, making them invaluable for real estate, architecture, and interior design.",
    },
    {
      type: "AV",
      src: "https://ramxero.s3.ap-south-1.amazonaws.com/Xeroscape+web+videos/km_20241012-1_1080p_60f_20241012_181632.mp4",
      alt: "Ariel Software",
      title: "Aerial View: Insight into Plot Layout",

      description:
        "An aerial view provides a bird's-eye perspective of a plot, revealing its layout, boundaries, and surroundings. This top-down view aids in efficient planning and assessment for real estate and construction projects.",
    },
    {
      type: "VR",
      src: "https://xeroverse-media.s3.ap-south-1.amazonaws.com/VR-walkthrough.mp4",
      alt: "VR Walkthrough",
      title: "Virtual Tour: Interactive Exploration",
      description:
        "A virtual tour is an interactive online experience that allows users to explore real-world locations remotely. Using 360-degree videos and images, it creates a sense of being there, making it ideal for exploring museums, real estate, and travel destinations from home.",
    },
  ];

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play().catch((error) => {
        console.error("Autoplay failed:", error);
      });
    }
  }, [currentVideo]);

  return (
    <>
      <div className="video_product">
        <section className="video-section">
          <h1 className="video-section-header">
            "Virtual Tours: Step into Your Dream Home, Anywhere, Anytime"
          </h1>
          <div className="video-container">
            <div className="video-buttons">
              {videos.map((video, index) => (
                <button
                  key={index}
                  className={`video-button ${
                    currentVideo === index ? "active" : ""
                  }`}
                  onClick={() => setCurrentVideo(index)}
                >
                  {video.type}
                </button>
              ))}
            </div>
            <div className="vid_wrap">
              <div className="video-wrapper">
                <video
                  ref={videoRef}
                  key={currentVideo}
                  src={videos[currentVideo].src}
                  controls
                  autoPlay
                  muted
                  loop
                  alt={videos[currentVideo].alt}
                  className="video"
                />
              </div>
              <div className="text-container">
                <h2 className="video-title">{videos[currentVideo].title}</h2>
                <p className="video-description">
                  {videos[currentVideo].description}
                </p>
                <button onClick={handleClick} className="info-button">
                  Know More
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
      {contactform && (
        <div
          className="contact-form-overlay"
          ref={overlayRef}
          onClick={handleOverlayClick}
        >
          <div className="contact-form-container">
            <ContactUS onClose={handleClose} />
          </div>
        </div>
      )}
    </>
  );
};

export default VideoSection;
