import React from "react";
import "./AboutUs.css";
import landing2 from "../../Asset/Image/Landing2.png";
import Prof from "../../Asset/Image/Intersect.png";
import Prof1 from "../../Asset/Image/prabhu.png";
import Ram from "../../Asset/Image/Ellipse 298.png";
import Shiva from "../../Asset/Image/Ellipse 299.png";
import Meva from "../../Asset/Image/Ellipse 305.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUsers,
  faGamepad,
  faCalendarAlt,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import visionIcon from "../../Asset/Image/vision.png";
import missionIcon from "../../Asset/Image/mission.png";

const About = () => {
  return (
    <div className="about-main">
      <section className="landing-section">
        <div className="landing-content">
          <h1 className="about-title">
            WHO <span>WE ARE</span>
          </h1>
          <p className="about-description">
            Xeroverse is transforming Extended Reality (XR) and Digital Twin
            technologies with captivating VR, AR, and MR experiences. Our expert
            team excels in software development, 3D modeling, and data
            analytics, delivering innovative solutions that enhance business
            visualization and optimization.
          </p>
        </div>
        <img
          className="landing-image"
          src={landing2}
          alt="Xeroverse technology"
        />
      </section>

      <section className="stats-section">
        <div className="stat-item">
          <FontAwesomeIcon icon={faUsers} className="stat-icon" />
          <p className="stat-number">15+</p>
          <p className="stat-label">Team</p>
        </div>
        <div className="stat-item">
          <FontAwesomeIcon icon={faGamepad} className="stat-icon" />
          <p className="stat-number">50+</p>
          <p className="stat-label">Services</p>
        </div>
        <div className="stat-item">
          <FontAwesomeIcon icon={faCalendarAlt} className="stat-icon" />
          <p className="stat-number">1+</p>
          <p className="stat-label">Years in Market</p>
        </div>
        <div className="stat-item">
          <FontAwesomeIcon icon={faUserTie} className="stat-icon" />
          <p className="stat-number">20+</p>
          <p className="stat-label">Clients</p>
        </div>
      </section>

      <section className="vision-mission-section">
        <div className="vision-mission-item">
          <img
            src={visionIcon}
            alt="Vision Icon"
            className="vision-mission-icon"
          />
          <div className="vision-mission-content">
            <h2 className="vision-mission-title">VISION</h2>
            <h3 className="vision-mission-subtitle">
              Reshaping Reality, Empowering Innovation
            </h3>
            <p className="vision-mission-text">
              We envision a world where the boundaries between physical and
              digital realms blur, unleashing unprecedented creativity and
              efficiency. Xeroscape aims to be the catalyst that transforms how
              businesses visualize, interact, and innovate across all
              industries.
            </p>
          </div>
        </div>
        <div className="vision-mission-item">
          <img
            src={missionIcon}
            alt="Mission Icon"
            className="vision-mission-icon"
          />
          <div className="vision-mission-content">
            <h2 className="vision-mission-title">MISSION</h2>
            <h3 className="vision-mission-subtitle">
              Democratizing Digital Realities
            </h3>
            <p className="vision-mission-text">
              We make XR and Digital Twin technologies accessible and
              transformative for all. Empowering businesses to innovate,
              visualize, and excel in the digital frontier. Bridging worlds,
              igniting imagination, and reshaping industries through affordable,
              cutting-edge solutions.
            </p>
          </div>
        </div>
      </section>

      <section className="team-section">
        <h2 className="section-title">Founding Team</h2>
        <p className="section-description">
          We are committed to creating a beautiful world of extended reality
        </p>
        <div className="team-grid">
          <div className="team-member">
            <img src={Prof} alt="Prof. Krishnan Balasubramanian" />
            <p className="member-name">Prof. Krishnan Balasubramanian</p>
            <p className="member-role">Mentor and Director</p>
          </div>
          <div className="team-member">
            <img src={Prof1} alt="Prof. Prabhu Rajagopal" />
            <p className="member-name">Prof. Prabhu Rajagopal</p>
            <p className="member-role">Mentor and Advisor</p>
          </div>
          <div className="team-member">
            <img src={Ram} alt="Ramashankar Yadav" />
            <p className="member-name">Ramashankar Yadav</p>
            <p className="member-role">CEO and Co-founder</p>
          </div>
          <div className="team-member">
            <img src={Shiva} alt="Sivaprasad KM" />
            <p className="member-name">Sivaprasad KM</p>
            <p className="member-role">Co-founder and XR Lead</p>
          </div>
          <div className="team-member">
            <img src={Meva} alt="Mevalal Nishad" />
            <p className="member-name">Mevalal Nishad</p>
            <p className="member-role">Co-founder and DevOps Lead</p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
