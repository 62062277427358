import Header from "../Header/Header";
import MidSec from "../Mid_Sec/category";
import Midbox from "../Mid_box/Mid_box";
import ToggleContent from "../ToggleContent/ToggleContent";
import FAQItem from "../FAQItem/FAQItem";
import TrustedByBrands from "../../TrustedByBrands/TrustedByBrands";
import TestimonialSlider from "../TestimonialSlider/TestimonialSlider";
import Services2 from "../Services2/Services2";
import Services3 from "../Services3/Services3";
import "./home.css";

function Home() {
  return (
    <div className="body_section">
      <Header />
      <MidSec />
      <ToggleContent />
      <TrustedByBrands />
      <Midbox />
      <TestimonialSlider />
      <Services2 />
      <Services3 />
      <FAQItem />
    </div>
  );
}
export default Home;
