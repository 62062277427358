import React from "react";
import "./Realestate.css";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

// import img3 from "../../Asset/video/video1.mp4";
// import LocationMap from "../../Asset/video/video2.mp4";
// import walkthrough3d from "../../Asset/video/Real estate - 3d Walkthrough.mp4";

// import VRwalkthrough from "../../Asset/video/VR-walkthrough.mp4";

// import webWalkthrough from "../../Asset/video/webWalkthrough.mp4";

// import DigiApartment from "../../Asset/video/dijidepartment.mp4";

// import ARexplore from "../../Asset/video/ARexplore.mp4";

const FLexSection = () => {
  const videoRefs = useRef([]);

  useEffect(() => {
    const currentVideoRefs = videoRefs.current; // Copy refs to a local variable

    const options = {
      threshold: 0.8, // Play video when 50% of it is visible
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const videoElement = entry.target;
        if (entry.isIntersecting) {
          videoElement.play(); // Play the video when it is in view
        } else {
          videoElement.pause(); // Pause the video when it is out of view
        }
      });
    }, options);

    // Observe each video
    currentVideoRefs.forEach((video) => {
      if (video) {
        observer.observe(video);
      }
    });

    // Clean up the observer when component unmounts
    return () => {
      currentVideoRefs.forEach((video) => {
        if (video) observer.unobserve(video); // Stop observing
      });
    };
  }, []);

  const FlexData = [
    {
      image:
        "https://xeroverse-media.s3.ap-south-1.amazonaws.com/VR-walkthrough.mp4",
      title: "Virtual Tour",
      description:
        "A virtual tour is an interactive online experience that lets users explore real-world locations remotely. It uses 360-degree videos and images to give viewers a sense of being there, making it popular for exploring museums, real estate properties, and travel destinations from home.",
    },
    {
      image:
        "https://xeroverse-media.s3.ap-south-1.amazonaws.com/ARexplore.mp4",
      title: "AR Exploration",
      description:
        "AR exploration blends digital elements with the real world, creating interactive experiences. It allows users to overlay virtual objects onto their surroundings using devices like smartphones, making it popular for gaming, education, and navigation, offering a new way to engage with both digital and physical environments. ",
    },
    {
      image:
        "https://xeroverse-media.s3.ap-south-1.amazonaws.com/Ariel+Software+optimized.mp4",
      title: "DigiApartment",
      description:
        "DigiApartment exploration allows users to virtually navigate apartment spaces, offering an immersive way to explore layouts, interiors, and features without being physically present. This technology is especially useful for real estate, providing a convenient and detailed view of properties to potential renters or buyers.",
    },
    {
      image:
        "https://xeroverse-media.s3.ap-south-1.amazonaws.com/3d+Walkthrough+web+optimized.mp4",
      title: "3D Walkthrough Video",
      description:
        "3D walkthrough videos offer immersive virtual tours of spaces, allowing viewers to explore properties, buildings, or designs in a lifelike, interactive way. These videos provide a realistic sense of scale and layout, making them a valuable tool for real estate, architecture, and interior design",
    },
    {
      image:
        "https://ramxero.s3.ap-south-1.amazonaws.com/Xeroscape+web+videos/webwalkthrough.mp4",
      title: "360 Web-Walkthrough",
      description:
        " 360 web walkthroughs allow users to explore spaces interactively from multiple angles, providing a panoramic view of locations. This immersive experience is ideal for virtual tours of real estate, hotels, or event venues, offering a detailed and engaging way to explore environments online. ",
    },
    {
      image: "https://xeroverse-media.s3.ap-south-1.amazonaws.com/video2.mp4",
      title: "3D Location Map",
      description:
        "A 3D location map overlays spatial information in three dimensions, offering a more immersive and realistic view of geographic locations. It enhances navigation by providing depth and detail, making it useful for urban planning, tourism, and virtual real estate exploration.",
    },
    {
      image: "https://xeroverse-media.s3.ap-south-1.amazonaws.com/video1.mp4",
      title: "3D Location Video",
      description:
        "A 3D location video showcases real-world environments in three dimensions, offering a dynamic and lifelike perspective of the space. It’s ideal for virtual tours, real estate, and tourism, giving viewers a more engaging and detailed understanding of the location.",
    },
    // Add more objects for additional rows
  ];

  return (
    <div className="flex-container">
      {FlexData.map((item, index) => (
        <div
          className={`product-container ${
            index % 2 === 0 ? "normal" : "reverse"
          }`}
          key={index}
        >
          <div className="product-content">
            <p className="titlee_text">{item.title}</p>
            <p className="desc">{item.description}</p>
          </div>
          <div className="product-image">
            <video
              className="pro_img"
              src={item.image}
              autoPlay={false} // Set to false to not play automatically, change to true if needed
              loop={false} // Set to true if you want the video to loop
              muted={true}
              // Set to true if the video should be muted initially
              ref={(el) => (videoRefs.current[index] = el)}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

const Realestate = () => {
  const [activeButton, setActiveButton] = useState("realestate");

  return (
    <>
      <div className="real-container">
        <div className="vid-container">
          <video className="background-video" autoPlay loop muted>
            <source
              src="https://xeroverse-media.s3.ap-south-1.amazonaws.com/3D_Walkthrough.mp4"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
          <div className="">
            <p className="realestate-text">
              Imagination is creation,so dwell in your new world.
            </p>
          </div>
        </div>
      </div>

      <div className="relestate-buttons">
        <button
          className={`real1-button ${
            activeButton === "realestate" ? "active" : ""
          }`}
          onClick={() => setActiveButton("realestate")}
        >
          Real Estate
        </button>

        <Link to="/product">
          <button
            className={`real2-button ${
              activeButton === "others" ? "active" : ""
            }`}
            onClick={() => setActiveButton("others")}
          >
            Others
          </button>
        </Link>
      </div>

      <FLexSection />
    </>
  );
};

export default Realestate;
