import React from "react";
import "./Services3.css"; // Import your CSS file

const services3 = () => {
  const impactData = [
    {
      value: "2.5x",
      description: "Growth in client base",
    },
    {
      value: "95%",
      description: "Customer satisfaction rate",
    },
    {
      value: "40+",
      description: "Projects successfully delivered",
    },
    {
      value: "100%",
      description: "Commitment to excellence",
    },
  ];

  return (
    <div className="different-from-service3">
      <p className="Differents3">Our Impact in Numbers</p>
      <div className="feature-cards3">
        {impactData.map((item, index) => (
          <div key={index} className="feature-card3">
            <p className="title_differents3">{item.value}</p>
            <p className="description_differents3">{item.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default services3;
