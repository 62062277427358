import React from "react";
import "./Service.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import ServiceItem from "./ServiceItem";
import VideoSection from "./VideoSection";
import {
  faDesktop,
  faVrCardboard,
  faMobileAlt,
  faDatabase,
  faServer,
  faCloud,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Import your images here
import headerImage1 from "../../Asset/Image/dt1.jpg";
import headerImage2 from "../../Asset/Image/dt2.jpg";
import headerImage3 from "../../Asset/Image/dt3.jpg";
import headerImage4 from "../../Asset/Image/dt4.jpg";
import headerImage5 from "../../Asset/Image/dt5.jpg";
import headerImage6 from "../../Asset/Image/dt6.jpg";
import service1 from "../../Asset/Image/service1.jpg";
import service2 from "../../Asset/Image/service2.jpg";
import service3 from "../../Asset/Image/service3.jpg";
import service4 from "../../Asset/Image/service4.jpg";
import service5 from "../../Asset/Image/service5.jpg";
import service6 from "../../Asset/Image/service6.jpg";

const Head = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const sliderContent = [
    { image: headerImage1, statement: "Immersive VR solutions for education" },
    {
      image: headerImage2,
      statement: "Cutting-edge AR for industrial training",
    },
    {
      image: headerImage3,
      statement: "VR engineer manipulates virtual engine model",
    },
    {
      image: headerImage4,
      statement: "Innovative MR applications for architecture",
    },
    {
      image: headerImage5,
      statement: "VR engineer examines holographic engine",
    },
    { image: headerImage6, statement: "Transformative XR for entertainment" },
  ];

  return (
    <div className="head2">
      <div className="slider-container">
        <Slider {...settings}>
          {sliderContent.map((slide, index) => (
            <div key={`slide-${index}`} className="slide-container">
              <div className="stmnt">{slide.statement}</div>
              <img
                src={slide.image}
                alt={`Scenic view ${index + 1}`}
                className="header-image"
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

const ServiceGrid = () => {
  const services = [
    { title: "Training Simulation", image: service1 },
    { title: "Architecture Visualization", image: service2 },
    { title: "3D Promotion", image: service3 },
    { title: "Virtual Fitting", image: service4 },
    { title: "Oil and Gas industries", image: service5 },
    { title: "Manufacturing", image: service6 },
  ];

  return (
    <section className="services">
      <p className="Our_Services">Our Services</p>
      <p className="Service_st">
        We focus more on the customer need when it comes to Extended Reality
      </p>
      <div className="service-grid">
        {services.map((service, index) => (
          <div key={index} className="service-itemss">
            <img src={service.image} alt={service.title} />
            <p>{service.title}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

const ServiceItem = ({ name, icon }) => (
  <div className="service-itemaa">
    <FontAwesomeIcon icon={icon} className="service-icon" />
    <h3>{name}</h3>
  </div>
);

const Projects = () => {
  const projects = [
    { name: "Serious Games", icon: faDesktop },
    { name: "Virtual Reality", icon: faVrCardboard },
    { name: "Augmented Reality", icon: faMobileAlt },
    { name: "Mobile Development", icon: faMobileAlt },
    { name: "Database Integrations", icon: faDatabase },
    { name: "Amazon Web Services", icon: faCloud },
    { name: "Dedicated Server Systems", icon: faServer },
    { name: "Cloud Systems", icon: faCloud },
  ];

  return (
    <div className="projects-container">
      <h2 className="projects-header">XEROVERSE PROVIDES TECH</h2>
      <div className="project-grid">
        {projects.map((project, index) => (
          <ServiceItem key={index} {...project} />
        ))}
      </div>
    </div>
  );
};

const Services = () => (
  <div className="services-container">
    <Head />
    <VideoSection />
    <ServiceGrid />
    <Projects />
  </div>
);

export default Services;
