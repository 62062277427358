import React from "react";
import "./Product.css";

const Product = ({
  index,
  title,
  subtitle,
  description,
  buttonText,
  imagePath,
  onButtonClick,
}) => {
  return (
    <div
      className={`produc-container ${index % 2 === 0 ? "normal" : "reverse"}`}
    >
      <div className="produc-content">
        <p className="titlee_text">{title}</p>
        <p className="desc">{description}</p>
        <button onClick={onButtonClick}>{buttonText}</button>
      </div>
      <div className="produc-image">
        <img className="pro_img" src={imagePath} alt={title} />
      </div>
    </div>
  );
};

export default Product;
