import React, { useState } from "react";
import xerologo from "../../Asset/Image/Xeroverse logo.png";
import ContactUs from "../ContactUs/ContactUs";
import { Link } from "react-router-dom";
import "./naav.css";

const Nav = () => {
  const [showContactForm, setShowContactForm] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // State to manage sidebar visibility

  const handleClick = () => {
    setShowContactForm(true);
  };

  const closeContactForm = () => {
    setShowContactForm(false);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen); // Toggle sidebar open/close
  };

  return (
    <div className="navv-container">
      <header className="header-nav">
        <Link to="/" className="logolink">
          <div className="logowrapper">
            <div className="logo3d">
              <img src={xerologo} alt="Xeroverse Logo" className="xerologo" />
            </div>
          </div>
        </Link>

        <ul className="ul-nav-container">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/about-us">About Us</Link>
          </li>
          <li>
            <Link to="/service">Service</Link>
          </li>
          <li>
            <Link to="/Blog">Blog</Link>
          </li>
          <li>
            <Link to="/RealEstate">Product</Link>
          </li>
        </ul>
        <button className="contactbutton" onClick={handleClick}>
          Contact Us
        </button>

        <div className="menunav" onClick={toggleSidebar}>
          <ion-icon name="menu-outline"></ion-icon>
        </div>

        {/* Sidebar for small screens */}
        <div className={`sidebar ${isSidebarOpen ? "open" : ""}`}>
          <ul className="ul-sidebar">
            <li>
              <Link to="/" onClick={toggleSidebar}>
                Home
              </Link>
            </li>
            <li>
              <Link to="/about-us" onClick={toggleSidebar}>
                About Us
              </Link>
            </li>
            <li>
              <Link to="/service" onClick={toggleSidebar}>
                Service
              </Link>
            </li>
            <li>
              <Link to="/Blog" onClick={toggleSidebar}>
                Blog
              </Link>
            </li>
            <li>
              <Link to="/RealEstate" onClick={toggleSidebar}>
                Product
              </Link>
            </li>
            <li>
              <button className="contactbutt" onClick={handleClick}>
                Contact Us
              </button>
            </li>
          </ul>
        </div>

        {showContactForm && <ContactUs onClose={closeContactForm} />}
      </header>
    </div>
  );
};

export default Nav;
