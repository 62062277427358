import React from "react";
import "./Mid_Sec.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HoverVideoPlayer from "react-hover-video-player";

// Import your images and videos
import travel from "../../Asset/Image/travel.jpg";
import architecture from "../../Asset/Image/arch.jpg";
import interior from "../../Asset/Image/interior.jpg";
import game from "../../Asset/Image/game1.jpg";
import food from "../../Asset/Image/food.jpg";
import Fashion from "../../Asset/Image/fashion (2).jpg";

const Category = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    lazyLoad: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const handleVideoError = (e) => {
    console.error("Error loading video:", e.target.src);
    // You can add a fallback image or some error message here
  };

  return (
    <>
      <div className="headercat">
        <h1> CATEGORY </h1>
      </div>
      <div className="mid_main">
        <div className="Animated_text">
          <Slider {...settings}>
            {data.map((caro_data) => (
              <HoverVideoPlayer
                key={caro_data.name}
                className="card"
                videoSrc={caro_data.backvid}
                pausedOverlay={
                  <div className="overlay">
                    <img
                      className="img_mid"
                      src={caro_data.img}
                      alt={caro_data.name}
                    />
                    <div className="text_container">
                      <div className="heading_text">{caro_data.name}</div>
                      <div className="text_content">{caro_data.text}</div>
                    </div>
                  </div>
                }
                loadingOverlay={
                  <div className="loading-overlay">
                    <div className="loading-spinner"></div>
                  </div>
                }
                restartOnPaused
                muted
                onError={handleVideoError}
              />
            ))}
          </Slider>
        </div>
      </div>
    </>
  );
};

export default Category;

const data = [
  {
    name: "Travel",
    text: "Enhance travel experiences with immersive XR technology.",
    img: travel,
    backvid:
      "https://xeroverse-media.s3.ap-south-1.amazonaws.com/travelBackvideo.mp4",
  },
  {
    name: "Interior",
    text: "Explore virtual showrooms with curated furniture and decor.",
    img: interior,
    backvid: "https://xeroverse-media.s3.ap-south-1.amazonaws.com/interior.mp4",
  },
  {
    name: "Architecture",
    text: "Enable collaboration between stakeholders, architects, and clients.",
    img: architecture,
    backvid:
      "https://xeroverse-media.s3.ap-south-1.amazonaws.com/Architecture.mp4",
  },
  {
    name: "Gaming",
    text: "Engage players in immersive environments and interactive storytelling.",
    img: game,
    backvid: "https://xeroverse-media.s3.ap-south-1.amazonaws.com/gaming.mp4",
  },
  {
    name: "Fashion",
    text: "Showcase products using 3D models and AR technology.",
    img: Fashion,
    backvid: "https://xeroverse-media.s3.ap-south-1.amazonaws.com/fashion.mp4",
  },
  {
    name: "Food",
    text: "View 3D models of food products through VR technology.",
    img: food,
    backvid: "https://xeroverse-media.s3.ap-south-1.amazonaws.com/food.mp4",
  },
];
