import React from "react";
import "./mid.css";
import digitaltwin from "../../Asset/Image/human_3061364.png";
import digitaltwi from "../../Asset/Image/virtual-reality.png";
import threedsolution from "../../Asset/Image/building_6017809.png";

const Mid_box = () => {
  return (
    <>
      <div className="headlineservice">
        <h1>SERVICES</h1>
      </div>
      <div className="extented_main">
        <div className="Extend_reality">
          <div className="service-item">
            <div className="service-inner">
              <div className="service-content">
                <div className="service-front">
                  <img
                    className="md_img"
                    src={digitaltwin}
                    alt="Digital Twin"
                  />
                  <h1 className="Heading_two">Digital Twin</h1>
                </div>
                <div className="service-back">
                  <p className="service-text">
                    Digital twins in XR merge virtual replicas with real-time
                    data, enabling immersive interaction for remote maintenance,
                    training, and predictive analysis. This technology
                    transforms complex system management by blending digital and
                    physical realms.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="service-item">
            <div className="service-inner">
              <div className="service-content">
                <div className="service-front">
                  <img
                    className="md_img"
                    src={digitaltwi}
                    alt="Extended Reality Solution"
                  />
                  <h1 className="Heading_one">Extended Reality Solution</h1>
                </div>
                <div className="service-back">
                  <p className="service-text">
                    XR solutions blend physical and virtual worlds using AR, VR,
                    and MR, revolutionizing digital interaction across
                    industries. These immersive tools enhance productivity,
                    streamline processes, and create engaging experiences.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="service-item">
            <div className="service-inner">
              <div className="service-content">
                <div className="service-front">
                  <img
                    className="md_img"
                    src={threedsolution}
                    alt="3D Promotion"
                  />
                  <h1 className="Heading_two">3D Promotion</h1>
                </div>
                <div className="service-back">
                  <p className="service-text">
                    3D services combine digital modeling and printing, driving
                    innovation across industries. From virtual tours in
                    architecture to 3D-printed organs in medicine, these
                    technologies enable rapid prototyping and customization,
                    transforming fields like engineering and entertainment.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Mid_box;
