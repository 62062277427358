import React from "react";
import "./Blog.css";
import BlogIMG1 from "../../Asset/Image/BlogIMG1.png";
import DeveloperIMG1 from "../../Asset/Image/DeveloperIMG1.png";
import DeveloperIMG2 from "../../Asset/Image/DeveloperIMG2.png";
import DeveloperIMG3 from "../../Asset/Image/DeveloperIMG3.png";

const blogPosts = [
  {
    date: "August 7, 2024",
    title:
      "Apple Vision Pro: Revolutionizing Spatial Computing and Mixed Reality",
    knowMoreLink: "https://www.example.com/apple-vision-pro-details",
  },
  {
    date: "August 5, 2024",
    title: "Meta's Project Aria: Advancing AR Technology for Everyday Use",
    knowMoreLink: "https://www.example.com/meta-project-aria-details",
  },
  {
    date: "August 3, 2024",
    title: "Unreal Engine 5.3 Introduces Groundbreaking XR Development Tools",
    knowMoreLink: "https://www.example.com/unreal-engine-xr-tools",
  },
  {
    date: "August 1, 2024",
    title:
      "Virtual Real Estate: 3D Walkthroughs Transforming Property Marketing",
    knowMoreLink: "https://www.example.com/virtual-real-estate-walkthroughs",
  },
];

const blogs = [
  {
    title: "Unity's Digital Twin: Bridging the Physical and Digital Worlds.",
    description:
      "Explore how Unity's latest advancements in digital twin technology are transforming industries by creating highly accurate digital replicas of physical environments.",
    image: DeveloperIMG1,
    knowMoreLink: "https://www.example.com/dev-blog-1-details",
  },
  {
    title: "ARKit 7: Bringing Augmented Reality to New Heights.",
    description:
      "Discover the new features in ARKit 7 and how they enable developers to create more immersive and interactive augmented reality experiences.",
    image: DeveloperIMG2,
    knowMoreLink: "https://www.example.com/dev-blog-2-details",
  },
  {
    title: "Mixed Reality in Manufacturing: Enhancing Productivity.",
    description:
      "Learn how mixed reality applications are being used in the manufacturing industry to improve efficiency, training, and maintenance.",
    image: DeveloperIMG3,
    knowMoreLink: "https://www.example.com/dev-blog-3-details",
  },
  {
    title: "3D Modeling with Blender: Tips and Tricks for Beginners.",
    description:
      "Get started with 3D modeling using Blender with these essential tips and tricks for beginners, from basic navigation to advanced techniques.",
    image: DeveloperIMG3,
    knowMoreLink: "https://www.example.com/dev-blog-4-details",
  },
  {
    title: "The Future of VR: Trends to Watch in 2025.",
    description:
      "Stay ahead of the curve by learning about the upcoming trends in virtual reality technology and how they will shape the future of digital experiences.",
    image: DeveloperIMG1,
    knowMoreLink: "https://www.example.com/dev-blog-5-details",
  },
  {
    title: "Creating Immersive Experiences with Unreal Engine 5.",
    description:
      "Discover the powerful tools and features in Unreal Engine 5 that are enabling developers to create stunning and immersive virtual environments.",
    image: DeveloperIMG2,
    knowMoreLink: "https://www.example.com/dev-blog-6-details",
  },
];

const developerBlogs = [
  {
    title:
      "Unreal Engine 5.3 Revolutionizes XR Development with Groundbreaking Features",
    description:
      "The latest version of Unreal Engine introduces a suite of cutting-edge tools and APIs that empower developers to create truly immersive extended reality experiences.",
    image: DeveloperIMG1,
    knowMoreLink: "https://www.example.com/dev-blog-1-details",
  },
  {
    title:
      "Meta's Project Aria: Unlocking the Potential of Everyday Augmented Reality",
    description:
      "Meta s groundbreaking Project Aria is poised to revolutionize how we perceive and interact with the world around us, blending digital information seamlessly into our physical environments.",
    image: DeveloperIMG2,
    knowMoreLink: "https://www.example.com/dev-blog-2-details",
  },
  {
    title: "Apple Vision Pro: Redefining the Future of Spatial Computing",
    description:
      "The upcoming Apple Vision Pro headset promises to revolutionize the way we interact with and experience the digital world, blurring the lines between reality and imagination.",
    image: DeveloperIMG3,
    knowMoreLink: "https://www.example.com/dev-blog-3-details",
  },
  {
    title: "Exploring the Untapped Potential of Location-Based AR Experiences",
    description:
      "As AR technology matures, developers are uncovering new ways to leverage location data and context-aware features to create captivating and immersive experiences.",
    image: DeveloperIMG3,
    knowMoreLink: "https://aircada.com/navigation-geolocation-with-web-ar/",
  },
];

const Blog = () => {
  const handleKnowMore = (link) => {
    window.open(link, "_blank");
  };

  return (
    <div className="blog-container">
      <div className="Blog_header">
        <h1 className="blog-title">Blogs</h1>
        <p className="blog-subtitle">
          Read our latest blogs on Extended Reality
        </p>
      </div>
      <div className="Blog_content">
        <div className="latest-blog">
          <img src={BlogIMG1} alt="Latest Blog" className="latest-image" />
          <span className="latest-label">Latest</span>
          <h2 className="latest-title">
            "Apple Vision Pro: Revolutionary Mixed Reality Headset Set to Launch
            in Early 2024"
          </h2>
        </div>
        <div className="other-blogs">
          {blogPosts.map((blog, index) => (
            <div key={index} className="blog-entry">
              <p className="blog-date">{blog.date}</p>
              <h3 className="blog-titl">{blog.title}</h3>
              <button
                className="know-more-btn"
                onClick={() => handleKnowMore(blog.knowMoreLink)}
              >
                Know More
              </button>
            </div>
          ))}
        </div>
      </div>
      <h2 className="blog-title">Latest</h2>
      <div className="blogs-grid">
        {blogs.map((blog, index) => (
          <div key={index} className="blog-entry">
            <img src={blog.image} alt={blog.title} className="blog-image" />
            <div className="blog-content">
              <h3 className="blog-entry-title">{blog.title}</h3>
              <p className="blog-description">{blog.description}</p>
              <button
                className="know-more-btn"
                onClick={() => handleKnowMore(blog.knowMoreLink)}
              >
                Know More
              </button>
            </div>
          </div>
        ))}
      </div>
      <h2 className="blog-title">From our developers</h2>
      <div className="developers-grid">
        {developerBlogs.map((blog, index) => (
          <div key={index} className="developer-entry">
            <img
              src={blog.image}
              alt={blog.title}
              className="developer-image"
            />
            <div className="developer-content">
              <h3 className="developer-title">{blog.title}</h3>
              <p className="developer-description">{blog.description}</p>
              <button
                className="know-more-btn"
                onClick={() => handleKnowMore(blog.knowMoreLink)}
              >
                Know More
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Blog;
