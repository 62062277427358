import React, { useState } from "react";
import "./Head.css";
import Asian_women from "../../Asset/Image/Asian_women.png";

const Header = () => {
  const [demovideo, setDemovideo] = useState(false);

  function handleClick() {
    setDemovideo(true);
  }

  function handleClose() {
    setDemovideo(false);
  }

  return (
    <>
      <div className="top_ladning">
        <div className="top_content">
          <div className="content">
            <div className="content_top">
              <p className="words2">LET'S</p>
              <div className="words">
                <span>CREATE</span>
                <span>ENVISION</span>
                <span>INHABIT</span>
              </div>
            </div>
            <div className="content_bottom">
              <p className="two">YOUR DREAM XR</p>
              <div className="cont_full">
                <p className="word1">
                  CONTENT <span>.</span>
                </p>
              </div>
            </div>
            <button className="btn_img">
              <div className="inner_button">
                <ion-icon name="play-circle-outline"></ion-icon>
                <p className="bt" onClick={handleClick}>
                  View Demo
                </p>
              </div>
            </button>
          </div>
          <div className="Asian_img">
            <img src={Asian_women} alt="asian_women" />
          </div>
        </div>
      </div>

      {/* Modal Popup */}
      {demovideo && (
        <div className="modal_overlay">
          <div className="demo_video_modal">
            <button className="close_button" onClick={handleClose}>
              &times;
            </button>
            <video
              src="https://xeroverse-media.s3.ap-south-1.amazonaws.com/3D_Walkthrough.mp4"
              autoPlay
              loop
              muted
              controls
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
